import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../../_helpers/FilterResponse";
import { customToastr } from "../../../_Pages/_webapp/arya2.0/CustomToastr";

const callApi = async (api, Method, data) => {
  try {
    const response = await RestMethod[Method](api, data);
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    customToastr({
      text: "Something went wrong. Please try again later",
      type: "error",
    });
    console.log("error detected while fetching data from api");
    return null;
  }
};

export const newlyAddedInventory = async (urlParamString) => {
  return callApi(
    `/v2/search/products${urlParamString ? urlParamString : ""}`,
    "GET"
  );
};
export const myFinance = async (urlParamString) => {
  return callApi(
    `/v2/home/my-finance${urlParamString ? urlParamString : ""}`,
    "GET"
  );
};
export const myTradeInterest = async (urlParamString) => {
  return callApi(
    `/v2/all-my-requirement${urlParamString ? urlParamString : ""}`,
    "GET"
  );
};

export const getWhoWeServeData = async (urlParamString) => {
  return callApi(
    `/v2/content/static${urlParamString ? urlParamString : ""}`,
    "GET"
  );
};

export const getBanners = async (urlParamString) => {
  return callApi(`/v2/banners${urlParamString ? urlParamString : ""}`, "GET");
};

export const myCommodities = async (urlParamString) => {
  return callApi(
    `/v2/arya-pro-stock${urlParamString ? urlParamString : ""}`,
    "GET"
  );
};

export const postWhatsappSubscribe = async (data) => {
  return callApi(`/v2/whatsapp/subscribe`, "POST", data);
};
export const postAppLink = async (data) => {
  return callApi(`/v2/app/link`, "POST", data);
};
export const getWhatsappSubscribe = async () => {
  return callApi(`/v2/whatsapp/subscribe`, "GET");
};

export const saveCommodity = async (data) => {
  return callApi(`/v2/save/commodity`, "POST", data);
};
export const getBrowseByCommodity = async () => {
  return callApi(`/v2/get/commodity`, "GET");
};
export const getActiveLoan = async () => {
  return callApi(`/v4/get-active-loan-list`, "POST");
};
export const getPendingItems = async () => {
  return callApi(`/v4/get-pending-items`, "POST");
};

export const getServiceRequest = async (queryString) => {
  return callApi(
    `/v4/get-service-request-list${queryString ? queryString : ""}`,
    "GET"
  );
};

export const getCaseDetails = async (dataObj) => {
  return callApi(`/v4/get-service-request-detail`, "POST", dataObj);
};

export const getMyTradeList = async () => {
  return callApi(`/v4/get-my-trade-list`, "GET");
};

export const getInTouch = async (dataObj) => {
  return callApi(`/v4/get-in-touch`, "POST", dataObj);
};

export const getInTouchInfo = async () => {
  return callApi(`/v4/get-in-touch-info`, "POST");
};
