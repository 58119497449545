import { RestMethod } from "../../../_helpers/ApiConfig/RestMethod";
import FilterResponse from "../../../_helpers/FilterResponse";
import { customToastr } from "../../../_Pages/_webapp/arya2.0/CustomToastr";
const callApi = async (api, Method, data, isFilterRequired = false) => {
  try {
    const response = await RestMethod[Method](api, data);
    if (isFilterRequired) return response.data;
    const api_response = FilterResponse(response); //If respone 200 to 299 Then return
    return api_response.data;
  } catch (error) {
    if (
      error?.data?.status_code === 422 &&
      error?.data?.message?.length > 0 &&
      error?.data?.message[0]?.includes("aadhar")
    ) {
      customToastr({
        text: error?.data?.message[0],
        type: "info",
      });
      // toastr.info(error?.data?.message[0]);
    } else {
      customToastr({
        text: "Something went wrong. Please try again later",
        type: "error",
      });
    }
    console.log("error detected while fetching data from api", error);
    return null;
  }
};

export const getUserLocPref = async (params) => {
  return callApi(`/v2/city/list` + params, "GET");
};
export const getUserCommodityPref = async (params) => {
  return callApi(`/v2/commodity/list` + params, "GET");
};

export const registerUpdateByMobile = async (data) => {
  return callApi(`v2/register-update-by-mobile?v3=1`, "POST", data, true);
};

export const verifyAadhar = async (data, params = "") => {
  return callApi(`v2/verify-aadhar${params}`, "POST", data);
};

export const updatePreferedCommodityLocation = async (data) => {
  return callApi(`v2/add-preferred-service`, "POST", data);
};
export const getUsersCommodityLocationPrefered = async () => {
  return callApi(`v2/preferred-service`, "GET");
};

export const getDigilockerAadharStatus = async (data) => {
  return callApi(`v2/check-digilocker-status`, "POST", data);
};
