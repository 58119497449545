import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import BlurModalContent from "../CommonComponents/BlurModal/BlurModalContent";
import BlurModalHeading from "../CommonComponents/BlurModal/BlurModalHeading";
import Button from "../CommonComponents/Button";
import { setUserInfo } from "../Redux/UserInfo/actions";
import { setUserLanguage } from "../Redux/UserLang/actions";
import HeadLines from "../CommonComponents/HeadLines";
import OptionButton from "../CommonComponents/OptionButton";
import useUserLanguage from "../CustomHooks/useUserLanguage";
import s from "./styles.module.css";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { languageOptions as options } from "./constants";

import MultiLanguageTranslation from "../../../../_components/Translate/MultiLangTranslate";
import { isMobile } from "../helperFunc";
import { getCommodityList } from "../../../../_services/Arya2.0/CommonServices";
import { setCommodityArray } from "../Redux/CommodityArray/actions";
import { eventTracker } from "../GATracker3.0/eventTracker";
import { CHANGE_LANGUAGE } from "../GATracker3.0/constants";
import { titleCase } from "../CommonFunctions/Function";
const SelectLangContent = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedId, setSelected] = useState(1);
  const userLanguage = useUserLanguage();
  const [isLanguageSelected, setIsLanguageSelected] = useState(false);
  const options = [
    { text: "English", id: 1, lang_id: "en" },
    { text: "हिंदी", id: 2, lang_id: "hindi" },
    { text: "मराठी", id: 3, lang_id: "marathi" },
    // { text: "অসমীয়া", id: 4, lang_id: "assamese" },
    { text: "తెలుగు", id: 5, lang_id: "telugu" },
    { text: "ગુજરાતી", id: 6, lang_id: "gujarati" },
  ];

  useEffect(() => {
    if (isLanguageSelected)
      getCommodityList().then((response) => {
        if (response !== null) {
          dispatch(setCommodityArray(response?.data ?? []));
          window.location.reload();
        }
      });
  }, [isLanguageSelected]);

  const onClick = (id) => {
    if (id == selectedId) {
      // setSelected(+id);
      return;
    } else {
      const selectedLanguageName = options.find((el) => el.id == id)?.lang_id;
      setSelected(options.find((el) => el.id == id)?.id);
      eventTracker("event", CHANGE_LANGUAGE, {
        lang: titleCase(selectedLanguageName),
      });
      localStorage.setItem("lang", options.find((el) => el.id == id)?.lang_id);
      i18n.changeLanguage(options.find((el) => el.id == id)?.lang_id);
      dispatch(setUserLanguage({ ...options.find((el) => el.id == id) }));
      setIsLanguageSelected(true);
    }
  };
  // const saveLanguage = () => {
  //   if (!selectedId) return false;
  //   localStorage.setItem(
  //     "lang",
  //     options.find((el) => el.id == selectedId)?.lang_id
  //   );
  //   i18n.changeLanguage(options.find((el) => el.id == selectedId)?.lang_id);
  //   dispatch(setUserLanguage({ ...options.find((el) => el.id == selectedId) }));
  //   window.location.reload();
  // };
  useEffect(() => {
    if (userLanguage) {
      setSelected(+userLanguage?.id);
    }
  }, []);
  useEffect(() => {
    if (selectedId) {
      if (selectedId == userLanguage?.id) {
        setIsLanguageSelected(false);
      }
    }
  }, [selectedId]);
  return (
    <>
      {isMobile && (
        <BlurModalHeading
          className={`padding-left16 padding-bottom16  ${s["padding-top20"]}`}
          heading={
            <HeadLines
              className={`font-weight600 font-size18 line-height21`}
              text={"Select Language"}
            />
          }
        />
      )}
      <BlurModalContent>
        <div
          className={`d-flex scrollHorizontal ${s["marginY-16"]} ${s["margin-top20"]} ${s["margin-bottom16"]} gap12`}
        >
          {options.map((option) => (
            <OptionButton
              isSelected={selectedId == option.id}
              id={option.id}
              selectedClassName={`${s["lang-selected-option"]}`}
              notSelectedClassName={`${s["lang-not-selected-option"]}`}
              text={
                <HeadLines
                  className="font-size12 line-height20 cursor-pointer"
                  text={option.text}
                />
              }
              onClick={onClick}
              textabc={option.text}
            />
          ))}
        </div>
        <hr className="m-0" />
        {/* <div className={`padding-left16 padding-right16 ${s["paddingY-12"]}`}>
          <Button
            className={`${isLanguageSelected ? s["bg-green"] : s["bg-grey"]} ${
              s["border-radius4"]
            }  width-100 padding-top8 padding-bottom8 backgroundWhite line-height16 font-size14 font-weight500 letterSpacing0125`}
            title={
              <HeadLines
                text={"Save"}
                className={`${
                  isLanguageSelected ? "text-white" : s["text-grey"]
                }`}
              />
            }
            disabled={!isLanguageSelected}
            onClick={saveLanguage}
          />
        </div> */}
      </BlurModalContent>
    </>
  );
};

export default SelectLangContent;
