import React, { useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import {
  getUserCommodityPref,
  getUserLocPref,
} from "../../../../_services/Arya2.0/Registration";
import Header from "../CommonComponents/Header";
import HeaderBackground from "../CommonComponents/HeaderBackground";
import HeadLines from "../CommonComponents/HeadLines";
import OverThePageComponent from "../CommonComponents/OverThePageComponent.js/OverThePageComponent";
import DesktopHeader from "../DesktopCommonComponents/DesktopHeader/DesktopHeader";
import { isMobile } from "../helperFunc";
import { backArrow } from "../icon_assets";
import { backArrowDesktop } from "../icon_assets";

import ProgressTab from "../Trade/ProgressTab/ProgressTab";
import AddDetails from "./AddDetails";
import {
  PROGRESS_TAB_STEPS,
  STEPS,
  textArray,
  BankSTEPS,
  BANK_PROGRESS_TAB_STEPS,
  desktopTextArray,
  disableSkipPaths,
} from "./Constant";
import KycVerification from "./KycVerification";
import SelectPref from "./SelectPref";
import SelectRole from "./SelectRole";
import style from "./style.module.css";
import { HomePageServices } from "../../../../_services/Arya2.0/HomePageServices";
import useIsUserLoggedIn from "../CustomHooks/useIsUserLoggedIn";
const initiate = {
  userType: "",
  Pan: "",
  Tan: "",
  Aadhar: "",
  Gst: "",
  panDoc: "",
  gstDoc: "",
  aadharDoc: "",
  preferred_buyer_locations: [],
  preferred_buyer_commodities: [],
  pincode: "",
  state: "",
  city: "",
  address: "",
  email: "",
  selectedCount: 0,
  msme: 0,
  farmerType: "",
  udyamCertificate: null,
  udyamCertificateNumber: "",
};

const Registration = ({ show, setShow }) => {
  //   const [stepNo, setStepNo] = useState(0);
  //   const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(initiate);
  const [userLoggedIn] = useIsUserLoggedIn();

  const [currentStep, setCurrentStep] = useState(STEPS.FIRST_STEP);
  const [completedStep, setCompletedStep] = useState(STEPS.FIRST_STEP);
  const history = useHistory();
  const [locDataArray, setLocDataArray] = useState([]);
  const [commodityDataArray, setCommodityDataArray] = useState([]);
  const [blurBackGround, setBlurBackGround] = useState(false);
  const arrowImage = isMobile ? backArrow : backArrowDesktop;
  const scrollRef = useRef();
  const location = useLocation();
  const ScrollToTop = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (!userLoggedIn && show) {
      setShow(false);
    }
  }, [userLoggedIn]);
  const updateKycNotification = async () => {
    const dataObj = { type: "USER_KYC", reference_data: { id: 1 } };
    const res = await HomePageServices.postUserNotification(dataObj);
    // if (res?.data?.length) {
    //   modifyData(res.data);
    // }
  };
  const getData = async () => {
    const res = await getUserLocPref("?type=preferred");
    if (res?.data?.length) {
      setLocDataArray(res?.data?.slice(0, 5));
    }
    const res1 = await getUserCommodityPref("?type=preferred");
    if (res1?.data?.length) {
      setCommodityDataArray(res1?.data?.slice(0, 5));
    }
  };
  const backClick = () => {
    if (currentStep != 1) {
      setCurrentStep((prev) => prev - 1);
      setCompletedStep((prev) => prev - 1);
    } else {
      setShow(false);
    }
  };
  let progressSteps = PROGRESS_TAB_STEPS;
  let totalSteps = STEPS.FOURTH_STEP;
  if (data.userType == "Banks & NBFC’s") {
    progressSteps = BANK_PROGRESS_TAB_STEPS;
    totalSteps = BankSTEPS.THIRD_STEP;
  }

  const disableSkip = disableSkipPaths.includes(location.pathname);

  return (
    <Modal
      className={`stdFontStyle ${style["edit-commodity"]} backgroundWhite ${
        blurBackGround ? style["blurBackGround"] : ""
      }`}
      backdropClassName="custom-backdrop"
      show={show}
    >
      <Modal.Body className={`p-0`}>
        <div ref={scrollRef}></div>
        {!isMobile && <DesktopHeader />}

        <HeaderBackground
          className={`${
            isMobile
              ? "borderRadiusBottom12 padding-right19 padding-left21 "
              : " padding-left-right100 "
          } linear-gradient1  `}
        >
          <Header
            title={isMobile ? "Welcome to arya.ag" : ""}
            backArrow={currentStep > 1 ? arrowImage : ""}
            onSecondaryIconClick={() => null}
            onBackArrowClick={backClick}
            className={`pb-0 ${
              isMobile ? "padding-top12" : "padding-top8"
            } font-size16 textColorBlack`}
            subtitle={
              isMobile
                ? textArray[
                    data.userType == "Banks & NBFC’s" && currentStep > 1
                      ? currentStep
                      : currentStep - 1
                  ]
                : desktopTextArray[
                    data.userType == "Banks & NBFC’s" && currentStep > 1
                      ? currentStep
                      : currentStep - 1
                  ]
            }
            subtitleClassName={`${
              isMobile
                ? "font-size12 line-height14 colorDark4 letterSpacing025"
                : "font-size16 line-height19 font-weight500 colorDark4 letterSpacing05"
            } padding-bottom8`}
            iconClassName="m-auto"
            backArrowClass={`${style["margin-top-neg-12"]} margin-right8`}
            divClassName={isMobile ? "margin-left8" : ""}
          />
        </HeaderBackground>
        <ProgressTab
          data={progressSteps}
          totalSteps={totalSteps}
          currentStep={completedStep}
          className={`${style["progressCSS"]} ${
            isMobile ? "" : "d-flex justify-content-center"
          }`}
          titleClassName={"colorAccent4"}
        />
        <div
          className={`${style["padding-bottom85"]} ${
            isMobile ? "" : "d-flex justify-content-center"
          }`}
        >
          <div className={isMobile ? "" : "d-width-1-col"}>
            {currentStep === STEPS.FIRST_STEP ? (
              <SelectRole
                setData={setData}
                userType={data.userType}
                setCurrentStep={setCurrentStep}
                setCompletedStep={setCompletedStep}
                setShow={setShow}
                setBlurBackGround={setBlurBackGround}
                updateKycNotification={updateKycNotification}
                disableSkip={disableSkip}
                ScrollToTop={ScrollToTop}
              />
            ) : currentStep === STEPS.SECOND_STEP &&
              data.userType != "Banks & NBFC’s" ? (
              <SelectPref
                setData={setData}
                data={data}
                setCurrentStep={setCurrentStep}
                setCompletedStep={setCompletedStep}
                locDataArray={locDataArray}
                commodityDataArray={commodityDataArray}
                setBlurBackGround={setBlurBackGround}
                ScrollToTop={ScrollToTop}
              />
            ) : (data.userType == "Banks & NBFC’s" &&
                currentStep === STEPS.SECOND_STEP) ||
              (data.userType != "Banks & NBFC’s" &&
                currentStep === STEPS.THIRD_STEP) ? (
              <KycVerification
                setData={setData}
                data={data}
                setCurrentStep={setCurrentStep}
                setCompletedStep={setCompletedStep}
                disableSkip={disableSkip}
                setShow={setShow}
                setBlurBackGround={setBlurBackGround}
                updateKycNotification={updateKycNotification}
                ScrollToTop={ScrollToTop}
              />
            ) : (
              <AddDetails
                setDataObj={setData}
                dataObj={data}
                setCurrentStep={setCurrentStep}
                setCompletedStep={setCompletedStep}
                setShow={setShow}
                setBlurBackGround={setBlurBackGround}
                ScrollToTop={ScrollToTop}
              />
            )}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default Registration;
