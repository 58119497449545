import {
  DOCUMENT_CLICKED,
  FAVORITES_CLICKED,
  MY_QUOTES_CLICKED,
  MY_STORE_CLICKED,
  RELEASE_LOG_CLICKED,
} from "../GATracker3.0/constants";

export const languageOptions = [
  { text: "English", id: 1, lang_id: "en" },
  { text: "हिंदी", id: 2, lang_id: "hindi" },
  { text: "मराठी", id: 3, lang_id: "marathi" },
  // { text: "অসমীয়া", id: 4, lang_id: "assamese" },
  { text: "తెలుగు", id: 5, lang_id: "telugu" },
  { text: "ગુજરાતી", id: 6, lang_id: "gujarati" },
];

export const profileLinkEventsObj = {
  Documents: DOCUMENT_CLICKED,
  Favourites: FAVORITES_CLICKED,
  "Delivery Orders": RELEASE_LOG_CLICKED,
  "My Quotes": MY_QUOTES_CLICKED,
  "My Store": MY_STORE_CLICKED,
};
