import {
  SET_OTP_UID,
  SET_USER_INFO,
  UPDATE_USER_INFO,
  UPDATE_USER_TU_INFO,
} from "./types";

export const setUserInfo = (payload) => {
  return { type: SET_USER_INFO, payload };
};

export const updateUserInfo = (payload) => {
  return { type: UPDATE_USER_INFO, payload };
};

export const updateUserTUInfo = (payload) => {
  return { type: UPDATE_USER_TU_INFO, payload };
};

export const setOTPUID = (payload) => {
  return { type: SET_OTP_UID, payload };
};
