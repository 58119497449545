import React, { useState } from "react";
import HeadLines from "../../CommonComponents/HeadLines";
import CustomCheckBox from "../../CommonComponents/CustomCheckBox/CustomCheckBox";
import style from "../style.module.css";
import InputFieldWithTitle from "../../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import Button from "../../CommonComponents/Button";
import CommonTable from "../Table/CommonTable";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { errorIcon, errorInfoIcon } from "../../icon_assets";
import MobileTableView from "../Table/MobileTable/index";

const ContactPerson = ({
  newContact,
  setNewContact,
  contactPersons,
  setContactPersons,
  contactErrors,
  setContactErrors,
  isMobile,
  isDisable,
  handleAddContact,
  handleInputChange,
  contactTableData,
  headers,
  handleSetAsPrimary,
  setIsEditing,
  setEditingIndex,
  sameAsUserDetails,
  emptyContactError
}) => {
  return (
    <>
      <div>
        <HeadLines
          text="Contact Persons"
          className={` ${
            isMobile
              ? `font-weight500 font-size14 line-height16 letterSpacing025 colorDark5 margin-bottom8`
              : `font-weight500 font-size16 line-height19 letterSpacing001 ${style["colorLightBlack"]} margin-bottom12`
          }`}
        />
        <HeadLines
          text="This person will receive billing related communication"
          className={`margin-bottom12 ${
            isMobile
              ? `font-weight500 font-size14 line-height16 letterSpacing025 colorDark9 margin-bottom8`
              : `font-weight500 font-size16 line-height19 letterSpacing001 colorDark9 margin-bottom12`
          }`}
        />
        <div
          className={`d-flex align-items-center gap4 margin-top16 margin-bottom16`}
        >
          <CustomCheckBox
            id="same_as_user_details"
            inputProps={{
              name: "sameAsUserDetails",
              checked: sameAsUserDetails,
              onChange: handleInputChange,
            }}
            className={style["mb2"]}
          />

          <HeadLines
            className={`font-weight400 font-size16 line-height19 colorDark5 letterSpacing05`}
            text={`Same as user details`}
          />
        </div>

        <div
          className={
            isMobile
              ? `d-flex align-items-center flex-column gap16`
              : style["contactPersonContainer"]
          }
        >
          <InputFieldWithTitle
            titleClassName={`${
              isMobile
                ? `font-weight500 font-size14 line-height16 letterSpacing05 colorDark9`
                : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
            }`}
            className={isMobile ? `width-100` : ``}
            title={"Contact Person Name"}
            disabled={false}
            isCompulsory={true}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? "font-size14 line-height16"
                : "font-size16 line-height19"
            } letterSpacing001`}
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style margin-top4"}
            showError={contactErrors?.name ? contactErrors?.name : ""}
            errorIcon={errorInfoIcon}
            value={newContact.name}
            name="name"
            onChange={handleInputChange}
            marginCSS={style["marginb0"]}
          />

          <InputFieldWithTitle
            titleClassName={`${
              isMobile
                ? `font-weight500 font-size14 line-height16 letterSpacing05 colorDark9`
                : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
            }`}
            className={isMobile ? `width-100` : ``}
            title={"Phone"}
            disabled={false}
            isCompulsory={true}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? "font-size14 line-height16"
                : "font-size16 line-height19"
            } letterSpacing001`}
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style margin-top4"}
            showError={contactErrors?.phone ? contactErrors?.phone : ""}
            errorIcon={errorInfoIcon}
            value={newContact.phone}
            name="phone"
            onChange={handleInputChange}
            marginCSS={style["marginb0"]}
            maxLength={10}
            type="number"
          />
          <InputFieldWithTitle
            titleClassName={`${
              isMobile
                ? `font-weight500 font-size14 line-height16 letterSpacing05 colorDark9`
                : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
            }`}
            className={isMobile ? `width-100` : ``}
            title={"Email"}
            disabled={false}
            isCompulsory={true}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? "font-size14 line-height16"
                : "font-size16 line-height19"
            } letterSpacing001`}
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style margin-top4"}
            showError={contactErrors?.email ? contactErrors?.email : ""}
            errorIcon={errorInfoIcon}
            value={newContact.email}
            name="email"
            onChange={handleInputChange}
            marginCSS={style["marginb0"]}
          />
          <InputFieldWithTitle
            titleClassName={`${
              isMobile
                ? `font-weight500 font-size14 line-height16 letterSpacing05 colorDark9`
                : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
            }`}
            className={isMobile ? `width-100` : ``}
            title={"Department"}
            disabled={false}
            isCompulsory={false}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? "font-size14 line-height16"
                : "font-size16 line-height19"
            } letterSpacing001`}
            value={newContact.department}
            name="department"
            onChange={handleInputChange}
            marginCSS={style["marginb0"]}
          />
          <InputFieldWithTitle
            titleClassName={`${
              isMobile
                ? `font-weight500 font-size14 line-height16 letterSpacing05 colorDark9`
                : `font-weight600 font-size14 line-height16 letterSpacing05 colorDark9`
            }`}
            className={isMobile ? `width-100` : ``}
            title={"Designation"}
            disabled={false}
            isCompulsory={false}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? "font-size14 line-height16"
                : "font-size16 line-height19"
            } letterSpacing001`}
            value={newContact.designation}
            name="designation"
            onChange={handleInputChange}
            marginCSS={style["marginb0"]}
          />
        </div>

        <div className={`d-flex align-items-center gap4 margin-top16`}>
          <CustomCheckBox
            id="primary-contact-checkbox"
            className="mb-auto"
            inputProps={{
              name: "primary",
              checked: newContact.primary,
              onChange: handleInputChange,
            }}
          />
          <HeadLines
            className={`${
              isMobile
                ? `font-weight500 font-size16 line-height19 colorDark5`
                : "font-weight400 font-size16 line-height19 colorDark5 letterSpacing05"
            }`}
            text={`Make this contact person as the primary contact person`}
          />
        </div>

        <div className="d-flex justify-content-center">
          <Button
            onClick={handleAddContact}
            className={`${isDisable && `${style["grayButton"]}`} 
              ${isMobile ? style["mobAddButton"] : style["addButton"]}`}
            title="Save"
            disabled={isDisable}
          />
        </div>

        {emptyContactError && (
          <div
            className={`d-flex align-items-center padding-top4 `}
          >
            <img
              src={errorIcon}
              className={`${
                style["errorCSS"]
              } display-inline-block margin-right4 ${
                isMobile ? "" : style["width18px"]
              }`}
              alt=""
            />

            <HeadLines
              className={`${style["errorCSS"]}
            font-size12 display-inline-block   ${
              isMobile ? "" : style["desktopCssImp"]
            }`}
              text="Add atleast one contact person"
            ></HeadLines>
          </div>
        )}
      </div>

      {isMobile ? (
        <MobileTableView
          data={contactTableData}
          isLoading={false}
          headers={headers}
          noDataFound={true}
          actionComponents={contactTableData.map((_, index) => (
            <ActionButton
              key={index}
              index={index}
              setContactPersons={setContactPersons}
              contactPersons={contactPersons}
              setNewContact={setNewContact}
              setIsEditing={setIsEditing}
              setEditingIndex={setEditingIndex}
              handleSetAsPrimary={handleSetAsPrimary}
            />
          ))}
        />
      ) : (
        <span className={style.contactTable}>
          <CommonTable
            headers={headers}
            subLabel={false}
            tableClassName={style.tableClassName}
            data={contactTableData}
            tableCell={style.tableCell}
            actionComponents={contactTableData.map((_, index) => (
              <ActionButton
                key={index}
                index={index}
                setContactPersons={setContactPersons}
                contactPersons={contactPersons}
                setNewContact={setNewContact}
                setIsEditing={setIsEditing}
                setEditingIndex={setEditingIndex}
                handleSetAsPrimary={handleSetAsPrimary}
              />
            ))}
          />
        </span>
      )}
    </>
  );
};

export default ContactPerson;

const ActionButton = ({
  setContactPersons,
  contactPersons,
  setNewContact,
  index,
  setIsEditing,
  setEditingIndex,
  handleSetAsPrimary,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);

  const handleEditClick = () => {
    setNewContact(contactPersons[index]);
    setIsEditing(true);
    setEditingIndex(index);
    setShowOverlay(false);
  };

  const handlePrimaryClick = () => {
    handleSetAsPrimary(index);
    setShowOverlay(false);
  };

  return (
    <OverlayTrigger
      trigger="click"
      placement="bottom"
      show={showOverlay}
      onToggle={(visible) => setShowOverlay(visible)}
      overlay={
        <Popover>
          <Popover.Content className="">
            <Button
              onClick={handlePrimaryClick}
              className={`${style["setButton"]} ${style["backWhite"]}`}
            >
              Set As Primary
            </Button>
          </Popover.Content>
          <Popover.Content>
            <Button
              onClick={handleEditClick}
              className={`${style["setButton"]} ${style["backWhite"]}`}
            >
              Edit
            </Button>
          </Popover.Content>
        </Popover>
      }
    >
      <Button
        variant="secondary"
        onClick={() => setShowOverlay(!showOverlay)}
        className={style["backWhite"]}
      >
        <div className={style["dot"]}></div>
        <div className={style["dot"]}></div>
        <div className={style["dot"]}></div>
      </Button>
    </OverlayTrigger>
  );
};
