import HoldSvg from "../CommonComponents/StatusIcons/HoldSvg";
import CrossSvg from "../CommonComponents/StatusIcons/CrossSvg";
import CheckSvg from "../CommonComponents/StatusIcons/CheckSvg";
import ClockSvg from "../CommonComponents/StatusIcons/ClockSvg";
import React from "react";

export const colorBorder = {
  Disbursed: "completed",
  Rejected: "rejected",
  Processing: "pending",
  "Approval Pending": "pending",
  "On Hold": "hold",
  Consideration: "pending",
  Expired: "expired",
};
export const disbursementStatuses = {
  Pending: {
    tag: "Pending",
    image: <ClockSvg height="16" width="16" />,
    color: "tagYellow",
  },
  Consideration: {
    tag: "Consideration",
    image: <ClockSvg height="16" width="16" />,
    color: "tagYellow",
  },
  Disbursed: {
    tag: "Disbursed",
    image: <CheckSvg height="16" width="16" />,
    color: "tagGreen",
  },
  failed: {
    tag: "Rejected",
    image: <CrossSvg height="20" width="20" />,
    color: "tagRed",
  },
  Expired: {
    tag: "Expired",
    image: <CrossSvg height="20" width="20" />,
    color: "tagRed",
  },
};

export const cardStatus = {
  0: "pending",
  1: "completed",
  2: "rejected",
};

export const statusOptions = [
  { value: "Pending", label: "Pending" },
  { value: "Consideration", label: "Consideration" },
  { value: "Disbursed", label: "Disbursed" },
];

export const selectedStatus = {
  Pending: 1,
  Consideration: 2,
  Disbursed: 5,
};

export const ListingStatus = {
  Processing: [<ClockSvg height="20" width="20" />],
  "Approval Pending": [<ClockSvg height="20" width="20" />],
  Consideration: [<ClockSvg height="20" width="20" />],
  Disbursed: [<CheckSvg height="20" width="20" />],
  Rejected: [<CrossSvg height="20" width="20" />],
  "On Hold": [<HoldSvg height="20" width="20" />],
  Expired: [<CrossSvg height="20" width="20" />],
};

export const bankDetail = [
  {
    label: "Bank Name & Branch",
    key: "bank",
  },
  {
    label: "Account Holder",
    key: "name",
  },
  {
    label: "Account Number",
    key: "account_number",
  },
  {
    label: "IFSC Code",
    key: "ifsc",
  },
];
export const lacDetail = () => {
  return [
    {
      field_name: "number",
      label: "Mobile",
      type: "phone",
      isRequired: true,
      min: 10,
      max: 10,
    },
    {
      field_name: "first_name",
      label: "First Name",
      type: "username",
      isRequired: true,
    },
    {
      field_name: "last_name",
      label: "Last Name",
      type: "username",
      isRequired: true,
    },
    {
      field_name: "address",
      label: "Address",
      type: "string",
      isRequired: true,
    },
    {
      field_name: "funding_address",
      label: "Funding Address ",
      type: "string",
      isRequired: true,
    },
    {
      field_name: "commodity",
      label: "Commodity",
      type: "string",
      isRequired: true,
    },
    {
      field_name: "email",
      label: "E-Mail ",
      type: "email",
      isRequired: true,
    },
    {
      field_name: "requirements",
      label: "Requirements",
      type: "string",
      isRequired: true,
    },
  ];
};
export const creditwalletMapping = [
  {
    field_name: "seller_name",
    label: "Beneficiary Name",
    type: "username",
    isRequired: true,
  },
  {
    field_name: "ifsc_code",
    label: "Bank IFSC Code",
    type: "ifsc",
    isRequired: true,
  },
  {
    field_name: "bank_name",
    label: "Bank Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "bank_branch",
    label: "Bank Branch",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "bank_city",
    label: "Bank City ",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "account_no",
    label: "Account Number",
    type: "account_no",
    isRequired: true,
  },
  {
    field_name: "account_no",
    label: "Confirm Account Number",
    type: "account_no",
    isRequired: true,
  },
  {
    field_name: "seller_mob_no",
    label: "Seller mobile number",
    type: "phone",
    isRequired: true,
    min: 10,
    max: 10,
  },
  {
    field_name: "seller_email",
    label: "Seller Email Address",
    type: "email",
    isRequired: true,
  },
];

export const invoiceMapping = [
  {
    field_name: "invoice_amount",
    label: "Invoice Amount",
    type: "number",
    isRequired: true,
  },
];

export const transferMapping = [
  {
    field_name: "transfer_amount",
    label: "Transfer Amount",
    type: "number",
    isRequired: true,
  },
];

export const applyCreditMapping = [
  {
    field_name: "full_name",
    label: "Name",
    type: "username",
    isRequired: true,
  },
  {
    field_name: "business_name",
    label: "Business Name",
    type: "string",
    isRequired: true,
  },
  {
    field_name: "pan",
    label: "PAN Number",
    type: "pan",
    isRequired: true,
  },
  {
    field_name: "phone_no",
    label: "Contact Number",
    type: "phone",
    isRequired: true,
  },
  {
    field_name: "email",
    label: "Email",
    type: "email",
    isRequired: true,
  },
  {
    field_name: "pincode",
    label: "Pincode",
    type: "pincode",
    isRequired: true,
  },
];

export const creditWalletForm = {
  uploadInvoice: 1,
  sellerDetails: 2,
  paymentDetails: 3,
};

export const PROGRESS_STEPS = [
  {
    stepNumber: creditWalletForm.uploadInvoice,
    stepText: "Upload Invoice",
  },
  {
    stepNumber: creditWalletForm.sellerDetails,
    stepText: "Seller Details",
  },
  {
    stepNumber: creditWalletForm.paymentDetails,
    stepText: "Payment Details",
  },
];

export const financePageBreadcrumb = [{ text: "Finance", url: "" }];

export const payWalletBreadCrumb = [
  { text: "Finance", url: "/finance" },
  {
    text: "Credit Wallet",
    url: "/finance/wallet",
  },
  { text: "Pay Wallet Amount", url: "" },
];

export const currentStatementBreadCrumb = [
  { text: "Finance", url: "/finance" },
  {
    text: "Credit Wallet",
    url: "/finance/wallet",
  },
  { text: "Current Statement", url: "" },
];
export const disbursementHistoryBRC = [
  { text: "Finance", url: "/finance" },

  { text: "Disbursement Detail", url: "", subText: "(Pre-approved)" },
];

export const transactionLedgerBreadCrumb = [
  { text: "Finance", url: "/finance" },
  {
    text: "Credit Wallet",
    url: "/finance/wallet",
  },
  { text: "Transaction Ledger", url: "" },
];

export const creditWalletBreadCrumb = [
  { text: "Finance", url: "/finance" },
  {
    text: "Credit Wallet",
    url: "",
  },
];

export const activeLoansBreadCrumb = [
  { text: "Finance", url: "/finance" },
  {
    text: "Active Loans",
    url: "",
  },
];

export const loanRequestsBreadCrumbs = [
  { text: "Finance", url: "/finance" },
  {
    text: "Loan Requests",
    url: "",
  },
];

export const disbursementRequstsBreadCrumb = [
  { text: "Finance", url: "/finance" },
  {
    text: "Disbursement Requests",
    url: "",
  },
];

export const uploadInvoiceBRC = [
  { text: "Finance", url: "/finance" },

  { text: "Upload New Invoice", url: "" },
];

export const allDisbursementsBRC = [
  { text: "Finance", url: "/finance" },

  { text: "All Disbursements", url: "" },
];

export const disbursementDetailBRC = [
  { text: "Finance", url: "/finance" },
  { text: "All Disbursements", url: "/disbursement" },

  { text: "Disbursement Detail", url: "" },
];

export const LACBreadCrumb = [
  { text: "Finance", url: "/finance" },

  { text: "Apply Loan Against Commodity", url: "" },
];
