import React from "react";
import styles from "./style.module.css";
import flowerTick from "./flowertick.svg";
import Button from "../../CommonComponents/Button";

const CommonTable = ({
  headers,
  data,
  total = 0,
  label = "",
  labelClassName = styles["userHeading"],
  subLabel = true,
  labelMandatory = false,
  headerRequired = true,
  className = "",
  tableClassName = styles["table"],
  scrollClass = styles["tableContainer"],
  buttons = [],
  tableTitle = "",
  headerClassName = "",
  dataRowClassName = "",
  boxClassName,
  actionClassName,
  actionComponents = [],
  tableCell = styles["table-cell"],
  columnStyles = {}, // New prop for custom styles
}) => {
  const totalCount = total || data?.length;

  return (
    <div className={`${className}`}>
      {headerRequired && (
        <div className={`${styles["header-div"]}`}>
          <div className={styles["totalUser"]}>
            <div className="d-flex align-items-center gap4">
              {boxClassName && <div className={boxClassName} />}
              {labelMandatory && <span className={styles["asterisk"]}>*</span>}
              <span className={labelClassName}>{label}</span>
            </div>
            {subLabel && (
              <span className={styles["userCount"]}>{totalCount}</span>
            )}
          </div>
          <div className={styles["buttonClasses"]}>
            {buttons.map((button, index) => (
              <div
                key={index}
                onClick={button.onClick}
                className={`${button.className} d-flex align-item-center gap4`}
              >
                {button.icon && button.iconSide !== "right" && (
                  <img src={button.icon} />
                )}
                <Button title={button.title} className={button.buttonClass} />
                {button.icon && button.iconSide === "right" && (
                  <img src={button.icon} />
                )}
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={scrollClass}>
        <div className={`${tableClassName}`}>
          {/* Render table headers */}
          <div
            className={`${styles["table-row"]} ${styles.header} ${headerClassName}`}
          >
            {headers.map((header, index) => (
              <div
                className={`${styles["table-cell"]} ${
                  columnStyles[header]?.className || ""
                }`}
                style={columnStyles[header]?.style || {}}
                key={index}
              >
                {header}
              </div>
            ))}
          </div>

          {data?.length > 0 &&
            data.map((row, rowIndex) => (
              <div
                className={`${styles["table-row"]} ${dataRowClassName} ${
                  rowIndex % 2 === 0 ? styles.even : styles.odd
                } ${
                  row["User status"] === "Inactive"
                    ? styles["lightPink"]
                    : "padding"
                }`}
                key={rowIndex}
              >
                {headers.map((header, cellIndex) => (
                  <div
                    className={`${tableCell} ${
                      columnStyles[header]?.className || ""
                    }`}
                    style={columnStyles[header]?.style || {}}
                    key={cellIndex}
                  >
                    {header === "Actions" ? (
                      // Render action component dynamically for each row
                      actionComponents[rowIndex] ? (
                        React.cloneElement(actionComponents[rowIndex], {
                          data: row, // Pass specific row data to the action component
                          rowIndex, // Pass row index
                        })
                      ) : null
                    ) : Array.isArray(row[header]) ? (
                      <span
                        className={`${styles["datarows"]} ${
                          row?.[header]?.onClick ? styles.pointer : ""
                        } ${row[header]?.style}`}
                        onClick={
                          row?.[header]?.onClick
                            ? () => row[header]?.onClick()
                            : null
                        }
                      >
                        {row[header]?.label || "--"}
                      </span>
                    ) : (
                      <span
                        className={`${styles["datarows"]} ${
                          row?.[header]?.onClick ? styles.pointer : ""
                        } ${row[header]?.style}`}
                        onClick={
                          row?.[header]?.onClick
                            ? () => row[header]?.onClick()
                            : null
                        }
                      >
                        {row[header]?.label || "--"}
                      </span>
                    )}
                    {row?.[header]?.showRightIcon ? (
                      <img src={flowerTick} alt="Verified" />
                    ) : null}
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default CommonTable;
