import style from "./style.module.css";

const MobileTableView = ({
  data,
  customClass,
  customKeyStyle,
  customValueStyle,
  children,
  headers,
  actionComponents = [],
}) => {
  return (
    <>
      {data && data.length > 0 && (
        <>
          {data.map((item, index) => (
            <div key={index}>
              {children}
              <div className={customClass || style.mobileCard}>
                {/* Iterate through keys of the item */}
                {Object.keys(item).map((key) => (
                  <div key={key} className={style.cardRow}>
                    <span
                      className={`${customKeyStyle || style.keyStyle} ${
                        style.cardRowFontStyle
                      }`}
                    >
                      {key}
                    </span>
                    {/* Render actionComponents if the key is "Actions" */}
                    {key === "Actions" && headers?.includes("Actions") ? (
                      <div className={style.actionContainer}>
                        {actionComponents[index] || "--"}
                      </div>
                    ) : (
                      <span
                        className={`${customValueStyle || style.valueStyle} ${
                          item[key]?.className || ""
                        } ${style.cardRowFontStyle}`}
                      >
                        {item[key]?.label || "--"}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default MobileTableView;
