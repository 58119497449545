import React, { useEffect, useState } from "react";
import style from "../style.module.css";
import HeadLines from "../../CommonComponents/HeadLines";
import { isMobile } from "../../helperFunc";
import { errorInfoIcon } from "../../icon_assets";
import InputFieldWithTitle from "../../CommonComponents/InputFieldWithTitle/InputFieldWithTitle";
import TextAreaWithTitle from "../../CommonComponents/TextAreaWithTitle/TextAreaWithTitle";
import textAreaStyles from "../../CommonComponents/TextAreaWithTitle/styles.module.css";
import { useTranslation } from "react-i18next";
import useAllUserData from "../../CustomHooks/useAllUserData";
import { headers, keysToBeUsedInTable } from "../Constant";
import UserComponent from "./UserComponent";
import ContactPerson from "./ContactPerson";

const BillingInformation = ({
  error,
  data,
  onChange,
  contactPersons,
  setContactPersons,
  newContact,
  setNewContact,
  newUser,
  setNewUser,
  allUserData,
  userErrors,
  setUserErrors,
  contactErros,
  setContactErrors,
  validateUser,
  validateContact,
  contactTableData,
  setContactTableData,
  setTotalContactsData,
  emptyContactError,
}) => {
  const { t: translation } = useTranslation();
  const [isDisable, setIsDisable] = useState(false);
  const [sameAsUserDetails, setSameAsUserDetails] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingIndex, setEditingIndex] = useState(null);

  const filterData = (userList) => {
    if (!Array.isArray(userList)) {
      console.error("Invalid data:", userList);
      return [];
    }

    return userList.map((ele, index) => {
      let newDataObj = {};
      headers.forEach((header) => {
        if (header === "Contact Person Name") {
          const nameWithPrimary = ele.primary ? (
            <span>
              {ele[keysToBeUsedInTable[header]]}
              <span className={`${style.primaryLabel} font-size8 line-height8`}>
                Primary
              </span>
            </span>
          ) : (
            ele[keysToBeUsedInTable[header]]
          );
          newDataObj[header] = {
            label: nameWithPrimary || "--",
          };
        } else {
          newDataObj[header] = {
            label: ele[keysToBeUsedInTable[header]] || "--",
          };
        }
      });
      return newDataObj;
    });
  };

 
  const isDuplicateContact = (phone, type) =>
    contactPersons.some(
      (contact) => contact[type] === phone
    );

  const handleAddContact = () => {
    const validationErrors = validateContact(newContact);

    if (Object.keys(validationErrors).length > 0) {
      setContactErrors(validationErrors);
      return;
    }

    if (!isEditing) {
         if (isDuplicateContact(newContact.phone,"phone")) {
            setContactErrors({
              phone: "This contact person is already created.",
            });
            return;
          }
          if (isDuplicateContact(newContact.email,"email")) {
            setContactErrors({
              email: "This contact person is already created.",
            });
            return;
          }
    }

    const updatedContacts = [...contactPersons];

    if (isEditing) {
      updatedContacts[editingIndex] = newContact;
    } else {
      if (newContact.primary) {
        updatedContacts.forEach((contact) => (contact.primary = false));
      }
      updatedContacts.push(newContact);
    }

    if (!updatedContacts.some((contact) => contact.primary)) {
      updatedContacts[0].primary = true; 
    }

    setContactPersons(updatedContacts);
    setContactTableData(filterData(updatedContacts));
    setTotalContactsData((prev) => {
      return [...updatedContacts];
    });
    setSameAsUserDetails(false);

    setNewContact({
      name: "",
      phone: "",
      email: "",
      department: "",
      designation: "",
      primary: false,
      receiveBilling: false,
    });
    setContactErrors({});
    setIsEditing(false);
    setEditingIndex(null);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "sameAsUserDetails") {
      setSameAsUserDetails(checked);
      if (checked) {
        // Set contact details same as user details
        setNewContact((prev) => ({
          ...prev,
          name: newUser.userName,
          phone: newUser.userPhone,
          email: newUser.userEmail,
        }));
      } else {
        setNewContact((prev) => ({
          ...prev,
          name: "",
          phone: "",
          email: "",
        }));
      }
    } else {
      setNewContact((prev) => ({
        ...prev,
        [name]: type === "checkbox" ? checked : value,
      }));
    }
  };

  useEffect(() => {
    if (contactPersons?.length > 0) {
      let isPrimaryExist =
        contactPersons && contactPersons?.length > 0
          ? contactPersons?.find((contact) => contact.primary)
          : false;
      setIsDisable(!isPrimaryExist);
    } else {
      if (!newContact?.primary) {
        setIsDisable(true);
      } else {
        setIsDisable(false);
      }
    }
  }, [contactPersons, newContact]);

  const handleUserInputChange = (e) => {
    const { name, value } = e.target;
    if (!name) return;

    setNewUser((prev) => {
      const updatedUser = {
        ...prev,
        [name]: value,
      };

      const validationErrors = validateUser(updatedUser);
      if (Object.keys(validationErrors).length > 0) {
        setUserErrors(validationErrors);
      } else {
        setUserErrors({});
      }

      return updatedUser;
    });
  };

  const handleSetAsPrimary = (index) => {
    setContactPersons((prevContacts) => {
      const updatedContacts = prevContacts.map((contact, i) => ({
        ...contact,
        primary: i === index, // Set only the clicked contact as primary
      }));
      setContactTableData(filterData(updatedContacts));
      return updatedContacts;
    });
  };

  useEffect(() => {
    setIsDisable(!contactPersons.some((contact) => contact.primary));
  }, [contactPersons]);

  // const handleDeleteContact = (index) => {
  //   setContactPersons(contactPersons.filter((_, i) => i !== index));
  // };
  const langArray = [
    { label: "English", value: "en" },
    { label: "Hindi", value: "hi" },
    { label: "Telugu", value: "te" },
    { label: "Marathi", value: "mr" },
    { label: "Gujarati", value: "gu" },
  ];

  return (
    <>
      {/* *************** Add User **********************************/}

      <UserComponent
        newUser={newUser}
        userErrors={userErrors}
        handleUserInputChange={handleUserInputChange}
        langArray={langArray}
        setNewUser={setNewUser}
      />

      <HeadLines
        text="Billing Information"
        className={`${
          isMobile
            ? `font-weight400 font-size14 line-height16 letterSpacing025 textColorBlack`
            : `font-weight500 font-size20 line-height24 letterSpacing001 colorDark1`
        } ${style["marginTop32"]}`}
      />
      <HeadLines
        text="Fill the details below to get information quickly"
        className={`font-weight400 font-size14 line-height16 letterSpacing001 colorDark9 padding-top8`}
      />

      <div
        className={`${
          isMobile ? `${style["mobBillingDiv"]}` : `${style["billingdiV"]}`
        } `}
      >
        <HeadLines
          text="Billing Address"
          className={`${style["marginBottom22"]} ${
            isMobile
              ? `font-weight400 font-size14 line-height16 letterSpacing025 textColorBlack`
              : `font-weight500 font-size18 line-height21 letterSpacing001 ${style["colorLightBlack"]}`
          }`}
        />

        <InputFieldWithTitle
          titleClassName={`font-size14 line-height16 letterSpacing05 colorDark9 ${
            isMobile ? `font-weight500` : `font-weight600`
          }`}
          title={"Pincode"}
          disabled={false}
          isCompulsory={true}
          compulsoryStarClassName="line-height14"
          inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
            style["inputBorder"]
          } font-weight400 ${
            isMobile ? "font-size14 line-height16" : "font-size16 line-height19"
          } letterSpacing001`}
          errorInputClassName={"input-style-error"}
          errorClassName={"input-error-style margin-top4"}
          showError={error?.pincode ? error.pincode : ""}
          errorIcon={errorInfoIcon}
          value={data.pincode}
          name="pincode"
          onChange={onChange}
          marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
        />

        <div
          className={`d-flex ${isMobile ? "gap16 flex-d-col" : "width-100"}`}
        >
          <InputFieldWithTitle
            showVerifyText={false}
            titleClassName={`font-size14 line-height16 letterSpacing05 colorDark9 ${
              isMobile ? `font-weight500` : `font-weight600`
            }`}
            title={"City"}
            disabled={true}
            isCompulsory={true}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? "font-size14 line-height16"
                : "font-size16 line-height19"
            } letterSpacing001`}
            disableInputClassName={`input-style-disable`}
            errorInputClassName={"input-style-error"}
            errorClassName={"input-error-style margin-top4"}
            showError={error?.city ? error.city : ""}
            errorIcon={errorInfoIcon}
            value={data.city}
            name="city"
            className={isMobile ? "" : "width-100"}
            marginCSS={isMobile ? "" : "margin-bottom24"}
          />
          <InputFieldWithTitle
            showVerifyText={false}
            titleClassName={`font-size14 line-height16 letterSpacing05 colorDark9 ${
              isMobile ? `font-weight500` : `font-weight600`
            }`}
            title={"State"}
            disabled={true}
            isCompulsory={true}
            compulsoryStarClassName="line-height14"
            inputClassName={`margin-top4 width-100 boxRadius4 padding12 ${
              style["inputBorder"]
            } font-weight400 ${
              isMobile
                ? "font-size14 line-height16"
                : "font-size16 line-height19"
            } letterSpacing001`}
            disableInputClassName={`input-style-disable`}
            errorInputClassName={"input-style-error "}
            errorClassName={"input-error-style margin-top4"}
            showError={error?.state ? error.state : ""}
            errorIcon={errorInfoIcon}
            value={data.state}
            name="state"
            className={isMobile ? "" : "width-100 margin-left20"}
            marginCSS={isMobile ? "margin-bottom16" : "margin-bottom24"}
          />
        </div>

        <TextAreaWithTitle
          titleClassName={`font-size14 line-height16 letterSpacing05 colorDark9 ${
            isMobile ? `font-weight500` : `font-weight600`
          }`}
          title="Full Address"
          showWordCount
          isCompulsory={true}
          compulsoryStarClassName="line-height14"
          // inputClassName={`${styles["input-style"]}  margin-top5`}
          // disableInputClassName={`${styles["input-style-disable"]}`}
          errorInputClassName={`input-style-error`}
          errorClassName={
            isMobile ? `input-error-style ` : style["desk-input-error-style"]
          }
          errorIconClassName={`margin-right4 ${
            !isMobile && style["desk-info-error-icon"]
          } `}
          showError={error?.address ? error.address : ""}
          errorIcon={errorInfoIcon}
          minLength={10}
          isLengthParenthesis={false}
          countClassName={textAreaStyles["word-count"]}
          textAreaClassName={`${textAreaStyles["textarea-style"]} ${style["height-80"]} margin-top5 margin-top8  `}
          textAreaProps={{
            placeholder: `${translation("Type Text Here....")}`,
            name: "address",
            value: data.address,
            onChange: onChange,
            maxLength: 300,
          }}
        />

        <div className={isMobile ? style["moblineCss2"] : style["lineCss2"]} />

        {/* *************** Contact Person**********************************/}

        <ContactPerson
          newContact={newContact}
          setNewContact={setNewContact}
          contactPersons={contactPersons}
          setContactPersons={setContactPersons}
          contactErrors={contactErros}
          setContactErrors={setContactErrors}
          isMobile={isMobile}
          isDisable={isDisable}
          handleAddContact={handleAddContact}
          handleInputChange={handleInputChange}
          contactTableData={contactTableData}
          headers={headers}
          handleSetAsPrimary={handleSetAsPrimary}
          setIsEditing={setIsEditing}
          setEditingIndex={setEditingIndex}
          sameAsUserDetails={sameAsUserDetails}
          emptyContactError={emptyContactError}
        />
      </div>
    </>
  );
};

export default BillingInformation;
