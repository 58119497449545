import React from "react";
import { useHistory } from "react-router";

import { useTranslation } from "react-i18next";
import BlurModal from "../../../../CommonComponents/BlurModal/BlurModal";
import BlurModalHeading from "../../../../CommonComponents/BlurModal/BlurModalHeading";
import { isMobile } from "../../../../helperFunc";
import { circleCheck } from "../../../../icon_assets";
import HeadLines from "../../../../CommonComponents/HeadLines";
import BlurModalContent from "../../../../CommonComponents/BlurModal/BlurModalContent";

const ThankyouPopUp = ({ show, setShow = true, popupCaseId = "" }) => {
  const history = useHistory();
  const { t: translation } = useTranslation();

  return (
    <>
      <BlurModal
        className={"blur-modal"}
        show={show}
        onHide={() => setShow(false)}
        showDesktopHeader={!isMobile}
        desktopHeading={"Case Created"}
      >
        <>
          {isMobile && (
            <BlurModalHeading
              className={`
                    padding-top24 
                   padding-bottom24
                    padding-right16
                    margin-top8
                    textCenter
                    `}
              heading={
                <>
                  <img src={circleCheck} alt="" />
                  <HeadLines
                    text={"Thank you"}
                    className={` colorDark1 font-weight700 ${
                      isMobile
                        ? "margin-top24"
                        : "font-size18 line-height21 letterSpacing05"
                    }`}
                  />
                </>
              }
              showHeadingSepertor={false}
            />
          )}
          <BlurModalContent className="text-center">
            {!isMobile && (
              <img
                src={circleCheck}
                alt=""
                className={`margin-top24 margin-bottom24`}
              />
            )}

            {!isMobile && (
              <HeadLines
                text={"Thank You"}
                className={`font-weight700 ${` colorDark1 font-weight700 ${
                  isMobile
                    ? ""
                    : "font-size18 line-height21 letterSpacing05 margin-bottom14"
                }`} colorDark1`}
              />
            )}
            <div
              className={`d-flex align-items-center justify-content-center margin-left16 margin-right16 ${
                isMobile
                  ? "margin-bottom24"
                  : "margin-bottom24 margin-left22 margin-right22"
              }`}
            >
              <HeadLines
                className={`font-weight400 
                      ${
                        isMobile
                          ? "font-size12 line-height14"
                          : "font-size18 line-height21"
                      }
                        letterSpacing025
                        colorDark9
                        `}
                text={`We have created a case with ID: ${popupCaseId} and assigned it to one of our representatives. Our team will reach out to you soon.`}
              />
            </div>
          </BlurModalContent>
        </>
      </BlurModal>
    </>
  );
};
export default ThankyouPopUp;
