import React, { useEffect, useState } from "react";
import HeadLines from "../CommonComponents/HeadLines";
import { isMobile } from "../helperFunc";
import {
  CardsData,
  skipArray,
  msmeCardData,
  nonMsmeCardData,
  farmerFarmSize,
} from "./Constant";
import SkipSubmitButton from "./SkipSubmitButton";
import style from "./style.module.css";
import Button from "../CommonComponents/Button";
import CustomRadioBtn from "../CommonComponents/CustomRadioBtn/CustomRadioBtn";
const SelectRole = (props) => {
  const FARM_SIZE = {
    large: "LARGE",
    small: "SMALL",
    marginal: "MARGINAL",
  };
  const [farmSizeRadio, setFarmSizeRadio] = useState(FARM_SIZE.marginal);
  const [msmeClick, setMsmeClick] = useState("");
  const [roleCards, setRoleCard] = useState([]);
  const [enableButton, setEnableButtom] = useState(false);
  const submitClick = () => {
    props.setCompletedStep(2);
    props.setCurrentStep(2);
  };
  useEffect(() => {
    props.ScrollToTop();
  }, []);

  const handleMsme = (id) => {
    setMsmeClick(id);
    setRoleCard(id === 1 ? msmeCardData : nonMsmeCardData);
    props.setData((prev) => ({
      ...prev,
      ["msme"]: id,
      ["farmerType"]: farmSizeRadio,
    }));
  };

  useEffect(() => {
    if (props.userType !== "Farmer") {
      props.setData((prev) => ({
        ...prev,
        ["farmerType"]: "",
      }));
    }
  }, [props.userType]);
  // const enableButton = () => {

  // };
  return (
    <div>
      <div className={`${isMobile && "padding-left16 padding-right16"}`}>
        <HeadLines
          text="Select your business category"
          className={`padding-left4 padding-right4 ${
            isMobile
              ? "margin-top16 font-weight400 font-size12 line-height14 letterSpacing025"
              : `margin-top24 font-weight500 colorDark5 font-size20 line-height23 letterSpacing001`
          }`}
        />
        <div className={`msme-btn margin-top16 d-flex gap20`}>
          <Button
            title={"MSME"}
            className={`boxRadius6   ${
              msmeClick === 1
                ? "backAccent3 textColorWhite"
                : "backgroundWhite colorDark1"
            }   ${
              isMobile
                ? ` font-size14 padding12 line-height16 letterSpacing0125 width-100 ${style["msme-buttons"]}`
                : `font-size18 line-height21 font-weight400 padding-top10 padding-bottom10 letterSpacing05 light2Border ${style["msme-desk-buttons"]}`
            }`}
            onClick={() => handleMsme(1)}
          />
          <Button
            title={"Non MSME"}
            className={`  ${
              msmeClick === 2
                ? "backAccent3 textColorWhite"
                : "backgroundWhite colorDark1"
            }  ${
              isMobile
                ? ` font-size14 padding12 line-height16 letterSpacing0125 width-100 light2Border ${style["msme-buttons"]}`
                : ` font-size18 line-height21 font-weight400 padding-top10 padding-bottom10 letterSpacing05 light2Border ${style["msme-desk-buttons"]}`
            }`}
            onClick={() => handleMsme(2)}
          />
        </div>
      </div>
      {msmeClick && (
        <div className={`${isMobile ? "padding-left16 padding-right16" : ""}`}>
          <HeadLines
            text="Select user type"
            className={`padding-left4 padding-right4 ${
              isMobile
                ? "margin-top16 font-weight400 font-size12 line-height14 letterSpacing025"
                : `margin-top24 font-weight500 colorDark5 font-size20 line-height23 letterSpacing001`
            }`}
          />
          <div
            className={`d-flex flex-wrap margin-top16  margin-bottom16 ${
              isMobile ? "padding4" : ""
            }`}
          >
            {roleCards.map((item, index) => {
              return (
                <>
                  <div
                    onClick={() => {
                      props.setData((prev) => ({
                        ...prev,
                        ["userType"]: item.label,
                      }));
                    }}
                    className={`cursorPointer boxRadius6 ${
                      props.userType == item.label
                        ? style["selectedBorder"]
                        : "light2Border"
                    }  ${
                      isMobile
                        ? `${style["flex45"]} ${style["margin-bottom14"]}`
                        : `${style["flex22"]} margin-bottom16 margin-top8`
                    } ${index % 2 ? style["margin-left20"] : ""}
                ${
                  !isMobile && (index == 2 || index == 6)
                    ? style["margin-left20"]
                    : ""
                } 
                `}
                  >
                    <div
                      className={`d-flex textCenter  ${
                        style[item.background]
                      } ${isMobile ? style["Height_95"] : style["Height_121"]}`}
                    >
                      <img
                        src={item.image}
                        className={`m-auto ${style[item.height]} width-100`}
                      />
                    </div>
                    <HeadLines
                      text={item.label}
                      className={`${
                        props.userType == item.label ? "colorAccent4" : ""
                      } textCenter padding8 font-weight500 ${
                        isMobile
                          ? "font-size12 line-height14"
                          : "font-size16 line-height19"
                      } letterSpacing025`}
                    />
                  </div>
                </>
              );
            })}
          </div>

          {msmeClick === 2 && props.userType === "Farmer" && (
            <div className={`margin-top32 `}>
              <HeadLines
                text="Choose farming category"
                className={`padding-left4 padding-right4 ${
                  isMobile
                    ? "margin-top16 font-weight400 font-size12 line-height14 letterSpacing025"
                    : `margin-top24 font-weight500 colorDark5 font-size20 line-height23 letterSpacing001`
                }`}
              />
              <div
                className={`margin-top16 light2Border boxRadius6 ${
                  isMobile
                    ? style["farm-size-style"]
                    : style["farm-desk-size-style"]
                } `}
              >
                {farmerFarmSize.map((item, index) => {
                  return (
                    <div
                      className={`d-flex align-items-start gap8 margin-bottom8`}
                      key={index + 1}
                    >
                      <CustomRadioBtn
                        inputProps={{
                          name: `${item.label}`,
                          // disabled: isAryaWareHouseDisabled,
                          checked:
                            farmSizeRadio ===
                            FARM_SIZE[item?.value?.toLocaleLowerCase()],
                          value: farmSizeRadio,
                          onChange: (e) => {
                            setFarmSizeRadio(item.value);
                            props.setData((prev) => ({
                              ...prev,
                              ["farmerType"]: farmSizeRadio,
                            }));
                          },
                        }}
                        id={item.label}
                      />
                      <label className="m-0" htmlFor={item.label}>
                        <>
                          <HeadLines
                            className={
                              !isMobile
                                ? `font-weight400 font-size16 line-height19 letterSpacing005 colorDark5`
                                : `font-weight400 font-size12 line-height16 letterSpacing005 colorDark5`
                            }
                            text={item.label}
                          />
                          <HeadLines
                            className={
                              !isMobile
                                ? `font-weight400 font-size14 line-height16 letterSpacing005 colorDark9 margin-top4`
                                : `font-weight400 font-size10 line-height12 letterSpacing005 colorDark9 margin-top10`
                            }
                            text={item.description}
                          />
                        </>
                      </label>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
        </div>
      )}
      <SkipSubmitButton
        submitClick={submitClick}
        buttonEnable={props.userType ? true : false}
        skipClick={() => {
          props.updateKycNotification();
          props.setShow(false);
        }}
        noSkip={props.disableSkip}
        skipText={skipArray[0]}
        setBlurBackGround={props.setBlurBackGround}
      />
    </div>
  );
};
export default SelectRole;
